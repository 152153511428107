import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "TIL about AnimateShareLayout from Framer Motion",
  "path": "/til/about-animatesharelayout-from-framer-motion",
  "date": "2020-09-04T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Video `}<a parentName="p" {...{
        "href": "https://www.learnwithjason.dev/let-s-learn-framer-motion"
      }}>{`https://www.learnwithjason.dev/let-s-learn-framer-motion`}</a><br parentName="p"></br>{`
`}{`Codesandbox `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/framer-motion-animatesharedlayout-app-store-demo-yn8y1"
      }}>{`https://codesandbox.io/s/framer-motion-animatesharedlayout-app-store-demo-yn8y1`}</a></p>
    <ol>
      <li parentName="ol">{`By adding `}<inlineCode parentName="li">{`<motion.div>`}</inlineCode>{` you can animate css state`}</li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`AnimateShareLayout`}</inlineCode>{` and pass `}<inlineCode parentName="li">{`layoutId`}</inlineCode>{` to connected element that want to animate`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`type = crossfade`}</inlineCode>{` is helpful to prevent junky animation while transitioning  `}</li>
    </ol>
    <p>{`Another resources:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://codesandbox.io/s/framer-motion-animatesharedlayout-menu-underline-demo-jwkvp"
        }}>{`Animated menu`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://codesandbox.io/s/framer-motion-automatic-animation-switch-demo-bgun4"
        }}>{`Simple toggle`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      